import React, { FC, useEffect, useState } from "react";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import ReviewItem from "components/ReviewItem";
import detail21JPG from "images/products/detail3-1.jpg";
import detail22JPG from "images/products/detail3-2.jpg";
import detail23JPG from "images/products/detail3-3.jpg";
import detail24JPG from "images/products/detail3-4.jpg";
import detail25JPG from "images/products/detail3-4.jpg";
import detail26JPG from "images/products/detail3-4.jpg";
import { TOYS_PRODUCTS } from "data/data";
import IconDiscount from "components/IconDiscount";
import NcInputNumber from "components/NcInputNumber";
import BagIcon from "components/BagIcon";
import AccordionInfo from "./AccordionInfo";
import Policy from "./Policy";
import toast from "react-hot-toast";
import { StarIcon } from "@heroicons/react/24/solid";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import ModalViewAllReviews from "./ModalViewAllReviews";
import NotifyAddTocart from "components/NotifyAddTocart";
import ProductsApi from "Apis/ProductsApi";
import CartsApi from "Apis/CartsApi";
import { useParams } from "react-router-dom";
import { useCart } from "components/CartContext";
import TokenManager from "Apis/TokenManager";

function isVideo(url: string) {
  return url?.match(/\.(mp4|webm|ogg)$/); // Check if it's a video URL based on file extension
}

export interface ProductDetailPage2Props {
  className?: string;
}

const ProductDetailPage2: FC<ProductDetailPage2Props> = ({
  className = "",
}) => {
  const { sizes, variants, status, allOfSizes } = TOYS_PRODUCTS[0];
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { setCart, formatNumber } = useCart();
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [variantActive, setVariantActive] = React.useState<any>();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [sizeSelected, setSizeSelected] = React.useState("");
  const [qualitySelected, setQualitySelected] = React.useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] =
    useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [product, setProduct] = useState<any>();
  const [selectedVariation, setSelectedvariation] = useState<any>(
    product?.variations[0]
  );
  const [description = 'N/A', features = 'N/A', additionalInformation = 'N/A', warnings = 'N/A'] = 
  (product?.description?.split('|') || []);


console.log("product?.description",description)
  const getCurrentProduct = async () => {
    setLoading(true);
    const response = await ProductsApi.getProductById(id);

    if (response.success) {
      setProduct(response.data);
      setSelectedvariation(response.data.variations[0]);
    } else {
      toast.error("something went wrong");
    }
    setLoading(false);
  };

  const handleAddToCart = async () => {
    if (!TokenManager.getAccessToken()) {
      window.location.href = "/login";
    }
    setAddToCartLoading(true);
    const payload = {
      item: {
        productId: product.id,
        variationId: selectedVariation.id,
        quantity: qualitySelected,
      },
    };
    const response = await CartsApi.addItem(payload);
    if (response.success) {
      setCart(response.data);
      notifyAddTocart();
    } else {
      toast.error("could not add to cart");
    }
    setAddToCartLoading(false);
  };

  useEffect(() => {
    getCurrentProduct();
  }, []);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  //
  const renderVariants = () => {
    return (
      <div className="flex items-center">
        <label htmlFor="">
          <span className="text-sm font-medium">
            Color:
            <span className="ml-1 font-semibold">{}</span>
          </span>
        </label>
        <div className="flex mt-3">
          {product?.variations.map((variant: any, index: any) => (
            <div
              key={index}
              onClick={() => setSelectedvariation(variant)}
              className={`relative w-6 h-6 rounded-full overflow-hidden z-10 border cursor-pointer ${
                selectedVariation?.id === variant.id
                  ? getBorderClass(variant.attributes[0].value)
                  : "border-transparent"
              }`}
              style={
                selectedVariation.id === variant.id
                  ? { borderColor: variant.attributes[0].value }
                  : { borderColor: "transparent" }
              }
              title={variant?.name}
            >
              <div
                className={`absolute inset-0.5 rounded-full z-0`}
                style={{ backgroundColor: variant.attributes[0].value }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const getBorderClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "border-red-500";
    }
    if (Bgclass.includes("violet")) {
      return "border-violet-500";
    }
    if (Bgclass.includes("orange")) {
      return "border-orange-500";
    }
    if (Bgclass.includes("green")) {
      return "border-green-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-blue-500";
    }
    if (Bgclass.includes("sky")) {
      return "border-sky-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-sky-500";
    }
    if (Bgclass.includes("yellow")) {
      return "border-yellow-500";
    }
    return "border-transparent";
  };
  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={selectedVariation.images[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={selectedVariation}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };
  const renderSizeList = () => {
    if (!allOfSizes || !sizes || !sizes.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
          </label>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="##"
            className="text-primary-6000 hover:text-primary-500"
          >
            See sizing chart
          </a>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-3">
          {allOfSizes.map((size, index) => {
            const isActive = size === sizeSelected;
            const sizeOutStock = !sizes.includes(size);
            return (
              <div
                key={index}
                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
                  sizeOutStock
                    ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                    : "cursor-pointer"
                } ${
                  isActive
                    ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                }`}
                onClick={() => {
                  if (sizeOutStock) {
                    return;
                  }
                  setSizeSelected(size);
                }}
              >
                {size}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const renderStatus = () => {
    if (!status) {
      return null;
    }
    const CLASSES =
      "text-sm flex items-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (status === "New in") {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "50% Discount") {
      return (
        <div className={CLASSES}>
          <IconDiscount className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "Sold Out") {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    if (status === "limited edition") {
      return (
        <div className={CLASSES}>
          <ClockIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{status}</span>
        </div>
      );
    }
    return null;
  };
  const renderSectionSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap lg:shadow-lg">
        <div className="space-y-7 lg:space-y-8">
          {/* PRICE */}
          <div className="">
            {/* ---------- 1 HEADING ----------  */}
            <div className="flex items-center justify-between space-x-5">
              <div className="flex text-2xl font-semibold">
                €{formatNumber(selectedVariation?.priceExcludingTax)}
              </div>

              <span className="ml-1 leading-none">
                {" "}
                Btw (21%) €{formatNumber(
                  selectedVariation?.priceIncludingTax
                )}{" "}
              </span>
              {/* <a
                href="#reviews"
                className="flex items-center text-sm font-medium"
              >
                <div className="">
                  <StarIcon className="w-5 h-5 pb-[1px] text-orange-400" />
                </div>
                <span className="ml-1.5 flex">
                  <span>4.9 </span>
                  <span className="mx-1.5">·</span>
                  <span className="text-slate-700 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </span>
              </a> */}
            </div>

            {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
            <div className="mt-6 space-y-7 lg:space-y-8">
              <div className="">{renderVariants()}</div>
              {/* <div className="">{renderSizeList()}</div> */}
            </div>
          </div>
          {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
          <div className="flex space-x-3.5">
            <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
              <NcInputNumber
                defaultValue={qualitySelected}
                onChange={setQualitySelected}
                quantity={qualitySelected}
                max={selectedVariation?.quantity}
              />
            </div>
            <ButtonPrimary
              className="flex-1 flex-shrink-0"
              onClick={handleAddToCart}
              disabled={addToCartLoading}
            >
              <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
              <span className="ml-3">Add to cart</span>
            </ButtonPrimary>
          </div>

          {/* SUM */}
          <div className="hidden sm:flex flex-col space-y-4 ">
            <div className="space-y-2.5">
              <div className="flex justify-between text-slate-600 dark:text-slate-300">
                <span className="flex">
                  <span>{`€${formatNumber(
                    selectedVariation?.priceExcludingTax
                  )}  `}</span>
                  <span className="mx-2">x</span>
                  <span>{`${qualitySelected} `}</span>
                </span>

                <span>{`€${formatNumber(
                  selectedVariation?.priceExcludingTax * qualitySelected
                )}`}</span>
              </div>
              <div className="flex justify-between text-slate-600 dark:text-slate-300">
                <span>Tax estimate</span>
                <span>{`€${formatNumber(
                  (selectedVariation?.priceIncludingTax -
                    selectedVariation?.priceExcludingTax) *
                    qualitySelected
                )}`}</span>
              </div>
            </div>
            <div className="border-b border-slate-200 dark:border-slate-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>{`€${formatNumber(
                selectedVariation?.priceIncludingTax * qualitySelected
              )}`}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        <div>
          <h2 className="text-2xl md:text-3xl font-semibold">
            {selectedVariation?.name}
          </h2>
          <div className="flex items-center mt-4 sm:mt-5">
            {/* <a
              href="#reviews"
              className="hidden sm:flex items-center text-sm font-medium "
            >
              <div className="">
                <StarIcon className="w-5 h-5 pb-[1px] text-slate-800 dark:text-slate-200" />
              </div>
              <span className="ml-1.5">
                <span>4.9</span>
                <span className="mx-1.5">·</span>
                <span className="text-slate-700 dark:text-slate-400 underline">
                  142 reviews
                </span>
              </span>
            </a> */}
            {/* <span className="hidden sm:block mx-2.5">·</span> */}
            {renderStatus()}

            <div className="ml-auto">
              <LikeSaveBtns />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="block lg:hidden">{renderSectionSidebar()}</div>

        {/*  */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/*  */}
        <AccordionInfo
          panelClassName="p-4 pt-3.5 text-slate-600 text-base dark:text-slate-300 leading-7"
          product={product}
          data={[
            {
              name: "Description",
              content: description,
            },
            {
              name: "Features",
              content: features,
            },
            {
              name: "Additional information",
              content: additionalInformation,
            },
            {
              name: "WARNING",
              content: warnings,
            },
          ]}
        />
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap !border-b-0 !pb-0">
        {/* <h2 className="text-2xl font-semibold">Product details</h2> */}
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
        {/* <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl">
          <p>
            The patented eighteen-inch hardwood Arrowhead deck --- finely
            mortised in, makes this the strongest and most rigid canoe ever
            built. You cannot buy a canoe that will afford greater satisfaction.
          </p>
          <p>
            The St. Louis Meramec Canoe Company was founded by Alfred Wickett in
            1922. Wickett had previously worked for the Old Town Canoe Co from
            1900 to 1914. Manufacturing of the classic wooden canoes in Valley
            Park, Missouri ceased in 1978.
          </p>
          <ul>
            <li>Regular fit, mid-weight t-shirt</li>
            <li>Natural color, 100% premium combed organic cotton</li>
            <li>
              Quality cotton grown without the use of herbicides or pesticides -
              GOTS certified
            </li>
            <li>Soft touch water based printed in the USA</li>
          </ul>
        </div> */}
        {/* ---------- 6 ----------  */}
        <Policy />
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div id="reviews" className="scroll-mt-[150px]">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold flex items-center">
          <StarIcon className="w-7 h-7 mb-0.5" />
          <span className="ml-1.5"> 4,87 · 142 Reviews</span>
        </h2>

        {/* comment */}
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-11 gap-x-28">
            <ReviewItem />
            <ReviewItem
              data={{
                comment: `My child absolutely loves the Happy Owl toy! It's incredibly soft and perfect for cuddling. It’s been through quite a few play sessions and still looks as good as new.`,
                date: "July 10, 2023",
                name: "Emily Johnson",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `The Happy Owl toy was a hit in our household! The colors are vibrant, and the materials are high-quality. My toddler can’t sleep without it.`,
                date: "June 18, 2023",
                name: "Michael Lee",
                starPoint: 5,
              }}
            />
            <ReviewItem
              data={{
                comment: `We were so impressed with the Happy Owl toy. It's durable, soft, and the perfect size for little hands. My daughter carries it everywhere!`,
                date: "May 5, 2023",
                name: "Sophia Martinez",
                starPoint: 5,
              }}
            />
          </div>

          <ButtonSecondary
            onClick={() => setIsOpenModalViewAllReviews(true)}
            className="mt-10 border border-slate-300 dark:border-slate-700 "
          >
            Show me all 142 reviews
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  const images = selectedVariation?.images.filter(
    (item: any) => !isVideo(item)
  );
  const videos = selectedVariation?.images.filter((item: any) => isVideo(item));

  return (
    <div
      className={`ListingDetailPage nc-ProductDetailPage2 ${className}`}
      data-nc-id="ProductDetailPage2"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container mt-8 sm:mt-10 flex flex-col lg:flex-row">
          {/* Main content (image/video section) */}
          <div className="relative w-full lg:w-2/3">
            {/* Big Image or Video */}
            <div
              className="relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer mb-4"
              style={{ height: "400px" }} 
              onClick={() => {
                if (isVideo(selectedVariation?.images[selectedImageIndex])) {
                  // Video, make it fullscreen
                  const videoElement = document.getElementById("videoElement");
                  if (videoElement && videoElement.requestFullscreen) {
                    videoElement.requestFullscreen();
                  }
                } else {
                  // Image, open modal
                  handleOpenModal(selectedImageIndex);
                }
              }}
            >
              {isVideo(hoveredImage || selectedVariation?.images[selectedImageIndex]) ? (
                <video
                  id="videoElement"
                  className="object-contain w-full h-full rounded-md sm:rounded-xl"
                  controls
                  autoPlay
                >
                  <source
                    src={hoveredImage || selectedVariation?.images[selectedImageIndex]}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  className="object-contain w-full h-full rounded-md sm:rounded-xl"
                  src={
                    hoveredImage ||
                    selectedVariation?.images[selectedImageIndex]
                  }
                  alt="Selected variation"
                />
              )}
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity"></div>
            </div>

            {/* Small Images/Video Slider */}
            <div className="flex space-x-1 overflow-x-auto scrollbar-hide ">
              {images?.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`relative w-24 h-24 rounded-md sm:rounded-xl overflow-hidden flex-shrink-0 cursor-pointer ${
                    selectedImageIndex === index
                      ? "ring-2 ring-blue-500"
                      : "hover:ring-2 hover:ring-gray-400"
                  }`}
                  onClick={() => setSelectedImageIndex(index)} // Select image on click
                  onMouseEnter={() => setHoveredImage(item)} // Hover to replace big image
                  onMouseLeave={() => setHoveredImage(null)} // Reset on mouse leave
                >
                  <img
                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                    src={item || ""}
                    alt="Variation"
                  />
                  <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity"></div>
                </div>
              ))}
              {videos?.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`relative w-24 h-24 rounded-md sm:rounded-xl overflow-hidden flex-shrink-0 cursor-pointer ${
                    selectedImageIndex === index + images.length
                      ? "ring-2 ring-blue-500"
                      : "hover:ring-2 hover:ring-gray-400"
                  }`}
                  onClick={() => setSelectedImageIndex(index + images.length)} // Select video on click
                  onMouseEnter={() => setHoveredImage(item)} // Hover to replace big image/video
                  onMouseLeave={() => setHoveredImage(null)} // Reset on mouse leave
                >
                  <video
                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                    autoPlay
                    muted
                  >
                    <source src={item} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-40 transition-opacity"></div>
                </div>
              ))}
            </div>
          </div>
          {/* Sidebar */}
          <div className="flex-grow m-4 lg:mt-0">
            <div className="hidden lg:block sticky top-28">
              {renderSectionSidebar()}
            </div>
          </div>
        </header>

        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={images}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ProductDetailPage2__modalPhotos"
        />
      </>

      {/* MAIN */}
      <main className="container relative z-10 mt-9 sm:mt-11 flex ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-10 lg:pr-14 lg:space-y-14">
          {renderSection1()}
          {renderSection2()}
        </div>
      </main>

      {/* OTHER SECTION */}
      <div className="container pb-24 lg:pb-28 pt-14 space-y-14">
        <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderProductCard
          heading="Customers also purchased"
          subHeading=""
          headingFontClassName="text-2xl font-semibold"
          headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
        />
      </div>

      {/* MODAL VIEW ALL REVIEW */}
      <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      />
    </div>
  );
};

export default ProductDetailPage2;
