import React, { FC, useEffect, useId, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import ProductsApi from "Apis/ProductsApi";
import { useCart } from "./CartContext";
import toast from "react-hot-toast";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: any[];
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "Vehicles & Transportation",
  data,
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const sliderInstance = useRef<any>(null); // To store Glide instance
  
  // Fetch products from API
  const getAllProducts = async () => {
    try {
      const response = await ProductsApi.getAllProducts();
      if (response.success) {
        setProducts(response.data.data.products);
      }
    } catch (error) {
      toast.error("Cannot get products");
      console.error("Failed to fetch products:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    // Wait until products are loaded and sliderRef is ready
    if (!sliderRef.current || products.length === 0) return;

    // Glide.js Options
    const OPTIONS= {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: { perView: 3 },
        1024: { gap: 20, perView: 3 },
        768: { gap: 20, perView: 2 },
        640: { gap: 20, perView: 1.5 },
        500: { gap: 20, perView: 1.3 },
      },
    };

    // Initialize Glide
    sliderInstance.current = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    sliderInstance.current.mount();

    // Cleanup
    return () => {
      if (sliderInstance.current) sliderInstance.current.destroy();
    };
  }, [products]); // Run when products are updated

  return (
    <div className={`nc-SectionSliderProductCard ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          hasNextPrev
        >
          {heading || `New Arrivals`}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <ul className="glide__slides">
              {products?.map((item: any, index: number) => (
                <li key={index} className={`glide__slide ${itemClassName}`}>
                  <ProductCard data={item} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionSliderProductCard;
